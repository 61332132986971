import { BubbleIcon, Button, Spacer, TextField } from '@missionlane/compass-ui';

interface TransactionsSearchProps {
  searchOpen: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setSearchOpen: (isOpen: boolean) => void;
}

export const TransactionsSearch = ({
  searchOpen,
  searchValue,
  setSearchValue,
  setSearchOpen,
}: TransactionsSearchProps) => {
  return (
    <>
      {searchOpen && (
        <div style={{ flexGrow: 1 }}>
          <TextField
            autoFocus
            name="Search transactions"
            icon="search"
            placeholder="Search transactions"
            value={searchValue}
            onChangeText={setSearchValue}
          />
        </div>
      )}
      <Spacer size="xs" />
      <button
        className="transaction-search__action-btn"
        aria-label={searchOpen ? 'Clear search' : 'Search transactions'}
        onClick={() => {
          setSearchOpen(!searchOpen);
          setSearchValue('');
        }}
      >
        <BubbleIcon
          name={searchOpen ? 'close' : 'search'}
          iconColor="blue"
          bubbleColor="blueWashed"
        />
      </button>
    </>
  );
};
