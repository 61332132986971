import { useQuery } from '@apollo/client';

import { useAccount } from '../Auth/AccountContext';
import { getPromptsDismissedAt } from './helpers';
import { PROMPTS_QUERY } from './promptsManagerQuery';
import PromptWrapper from './PromptWrapper';
import {
  PromptsManagerQuery,
  PromptsManagerQueryVariables,
} from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';

const Prompts = () => {
  const { accountId } = useAccount();
  const dismissedAt = getPromptsDismissedAt();

  const { loading, data, error } = useQuery<
    PromptsManagerQuery,
    PromptsManagerQueryVariables
  >(PROMPTS_QUERY, {
    skip: accountId == null,
    variables: {
      accountId: accountId ?? '',
      dismissedAt,
    },
  });

  if (loading) return null;

  if (error) {
    // Log error but don't show anything to the user
    TrackService.trackError('Prompts Query Error', {
      code: 'PROMPTS_QUERY_ERROR',
      error,
      feature: 'Prompts',
      message: 'Error querying prompts',
      metadata: {
        accountId,
        dismissedAt,
      },
    });

    return null;
  }

  return (
    <>
      {data?.promptsManager?.prompts?.map((promptWrapperProps) => (
        <PromptWrapper key={promptWrapperProps.id} {...promptWrapperProps} />
      ))}
    </>
  );
};

export default Prompts;
