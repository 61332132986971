import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const KardCopy: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div className={cx('light-ink lh-copy f7', className)}>{children}</div>
);

KardCopy.defaultProps = {
  className: '',
};

KardCopy.propTypes = {
  className: PropTypes.string,
};

export default KardCopy;
