import { gql, useLazyQuery } from '@apollo/client';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { CustomerAccountsQuery } from '@core/graphql/globalTypes';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';

const CUSTOMER_ACCOUNTS_QUERY = gql`
  query CustomerAccounts {
    customer {
      id
      accounts {
        id
      }
    }
  }
`;

interface IAccountIdContext {
  accountId: string;
  updateAccountId: (id: string) => void;
}

export const AccountIdContext = createContext<IAccountIdContext>({
  accountId: '',
  updateAccountId: () => {},
});

export const AccountIdProvider = ({ children }: PropsWithChildren) => {
  // We will initialize our accountId in state with whatever is already in local storage if it exists.
  // This would happen only on a remount after it has been set for the first time.
  const [accountId, setAccountId] = useState(
    localStorage.getItem('accountId') || '',
  );
  const location = useLocation();
  const accountIdParam = location.pathname.match(/^\/account\/(\d+)/)?.[1];
  const [getCustomerAccounts, { loading, error }] =
    useLazyQuery<CustomerAccountsQuery>(CUSTOMER_ACCOUNTS_QUERY, {
      onCompleted: (data) => {
        const customerAccounts = data.customer?.accounts;
        if (customerAccounts && customerAccounts.length) {
          updateAccountId(customerAccounts[0].id);
        }
      },
    });

  const updateAccountId = (id: string) => {
    setAccountId(id);
    localStorage.setItem('accountId', id);
  };

  useEffect(() => {
    // Whenever a page loads with an accountId in its params...
    if (accountIdParam) {
      // ...we need to make sure we set that value in state and local storage
      updateAccountId(accountIdParam);
    } else if (!accountId) {
      // If we don't have an account id already set and there is none in the params,
      // we need to fetch the customer's list of accounts. When this completes successfully,
      // we will default to the first account in the list and set it in local storage and context
      getCustomerAccounts();
    }
  }, [accountIdParam]);

  if (loading)
    return <LoadingSpinnerPage showHeader centerContent withFooter />;

  if (error)
    return <GenericFallbackFullPage showHeader centerContent withFooter />;

  return (
    <AccountIdContext.Provider
      value={{ accountId: accountId, updateAccountId }}
    >
      {children}
    </AccountIdContext.Provider>
  );
};

export const useAccountId = () => useContext(AccountIdContext);
