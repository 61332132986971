import { createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { PageHeader } from '../Page/PageHeader';
import Footer from '../General/Footer/Footer';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import { useAccountId } from '../App/Routes/AccountIdContext';
import Nav from './Nav/Nav';
import { SIDEBAR_NAVIGATION_FEATURE_ID } from './Nav/constants';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useTracking } from '@core/services/TrackService/useTracking';
import { NavContainerQuery } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';

const NAV_CONTAINER_QUERY = gql`
  query NavContainer($accountId: String!) {
    account(accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
      }
      statuses {
        isChargedOff
        isBankrupt
      }
    }
    creditCards {
      id
      name
      cardLast4
      cardArtUrl
    }
    customer {
      id
      hasPendingAccount
      contactInfo {
        firstName
      }
    }
    offers(accountId: $accountId) {
      offerId
      offerType
      data {
        state
        subType
      }
    }
    referralOffers(accountId: $accountId) {
      data {
        offerId
      }
    }
    offerEligibility(accountId: $accountId) {
      offerType
      eligible
      ineligibleReasons
    }
    rewards(accountId: $accountId) {
      accountId
      isRewardsCard
    }
  }
`;

type NavContainerContext = { contentClassName: string | undefined };
const NavContainerContext = createContext<NavContainerContext>({
  contentClassName: undefined,
});

const NavContainer = () => {
  const { trackClick } = useTracking();
  const { isMobile } = useUserDevice();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const contentClassName = !isMobile ? 'ma4' : 'mb4';
  const { accountId } = useAccountId();
  const { data, loading, error } = useQuery<NavContainerQuery>(
    NAV_CONTAINER_QUERY,
    {
      errorPolicy: 'all',
      variables: {
        accountId,
      },
    },
  );

  useEffect(() => {
    // Prevent the body from being scrollable when mobile menu is open
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'unset';
  }, [isMobileMenuOpen]);

  if (loading)
    return <LoadingSpinnerPage showHeader centerContent withFooter />;

  // If we failed to fetch the customer's accounts we shouldn't display anything
  if (!data || (error && !data.creditCards.length)) {
    const errorMessage = 'NavContainer: Failed to fetch customer accounts';
    MLError.report({ name: errorMessage });
    throw new Error(errorMessage);
  }

  return (
    <>
      {isMobile && (
        <PageHeader
          onMenuOpen={() => {
            setIsMobileMenuOpen(true);
            trackClick({
              name: 'Mobile Sidebar Nav Opened',
              feature: SIDEBAR_NAVIGATION_FEATURE_ID,
            });
          }}
        />
      )}
      <div
        style={
          !isMobile ?
            {
              display: 'grid',
              gridTemplateColumns: '256px calc(100vw - 256px)',
            }
          : undefined
        }
      >
        <Nav
          data={data}
          isMobile={isMobile}
          isMobileOpen={isMobileMenuOpen}
          onMobileClose={() => setIsMobileMenuOpen(false)}
        />
        <NavContainerContext.Provider value={{ contentClassName }}>
          <Outlet />
        </NavContainerContext.Provider>
      </div>
      <Footer contentClassName={!isMobile ? 'ml8 mr4' : undefined} />
    </>
  );
};

export const useNavContainerContext = () => useContext(NavContainerContext);

export default NavContainer;
