import { Button, Spacing } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import { confettiJson } from './utils/confetti';
import PageWrapper from '@core/components/Page/PageWrapper';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';
import { useAccount } from '@core/components/Auth/AccountContext';
import {
  CLIP_TRACKER_FEATURE_NAME,
  ClipEventNames,
} from '@clip/shared/lib/events';
import { useTracking } from '@core/services/TrackService/useTracking';

const MessageGroup = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  return (
    <div>
      <h3 className="mb5">{title}</h3>
      <p className="mw6">{message}</p>
    </div>
  );
};

const StreakGroupSuccess = () => (
  <MessageGroup
    title="You’ve got a payment streak going!"
    message="Build your streak by making your payments on time each month."
  />
);

const TrackerGroupSuccess = () => (
  <MessageGroup
    title="You’ve unlocked a payment tracker"
    message="Check out your progress towards a potential credit limit increase."
  />
);

const PAYMENT_SUCCESS_STREAK_CONTENT: Record<string, () => JSX.Element | null> =
  {
    STREAK: StreakGroupSuccess,
    TRACKER: TrackerGroupSuccess,
    '': () => null,
  } as const;

const PaymentSuccessScreen = () => {
  const navigate = useNavigate();
  const { trackClick } = useTracking();
  const { accountId } = useAccount();
  const clipOffer = useClipOfferV2();

  const screenName = 'Payment Done';
  const eventTrackingName = 'Payment Streak Screen: See My Progress';
  const featName = 'Payment Streak Screen';

  const isTrackerEnrolled = clipOffer?.experience === 'TRACKER';
  const isFirstPayment = clipOffer?.currentPaymentStreak === null;
  const showTracker = isTrackerEnrolled && isFirstPayment;
  const Content = PAYMENT_SUCCESS_STREAK_CONTENT[clipOffer?.experience ?? ''];
  const buttonText = showTracker ? 'See Tracker' : 'See Streak';

  const handleNavigation = () => {
    trackClick({
      name: eventTrackingName,
      feature: featName,
    });
    navigate(`/account/${accountId}/my-progress`);
  };

  return (
    <PageWrapper
      trackingProperties={{
        pageName: screenName,
        featureName: featName,
      }}
    >
      <Lottie
        animationData={confettiJson}
        autoPlay
        loop={false}
        style={{
          position: 'absolute',
          top: 0,
          height: `${Spacing.l}rem`,
          width: `${Spacing.l}rem`,
        }}
      />
      <div>
        <Content />
        <div className="flex">
          <Button text={buttonText} onPress={handleNavigation} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default PaymentSuccessScreen;
