import { FC, useLayoutEffect, useState } from 'react';

import { PromptWrapperProps, promptsMap, setPromptDismissed } from './helpers';
import { PromptId } from '@core/graphql/globalTypes';

const PromptWrapper: FC<PromptWrapperProps> = (promptWrapperProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    /**
     * Due to how the BottomSheet component is implemented, we need to delay the opening of the modal
     * until the next render cycle to ensure that the modal is displayed correctly and the animation
     * is smooth.
     */
    setIsOpen(true);
  }, []);

  const handleDismiss = (promptId: PromptId) => {
    setPromptDismissed(promptId);

    /**
     * We need to close the modal after dismissing the prompt to ensure that the modal is removed
     * and the animation has time to complete.
     */
    setIsOpen(false);
  };

  const PromptComponent = promptsMap[promptWrapperProps.id];

  // skip unsupported prompts, useful when adding new prompts
  if (!PromptComponent) return null;

  // We need to always render the component to ensure that the modal is displayed correctly
  return (
    <PromptComponent
      {...promptWrapperProps}
      isOpen={isOpen}
      onDismiss={handleDismiss}
    />
  );
};

export default PromptWrapper;
