import { TextField } from '@missionlane/compass-ui';
import { TextFieldProps } from '@missionlane/compass-ui/lib/typescript/components/Input/TextField/TextField';
import { Controller, useFormContext } from 'react-hook-form';

interface HookFormTextFieldProps extends TextFieldProps {
  name: string;
  /** Before passing to onChange, transform the input to a desired format */
  transformInput?: (value: string) => string;
}

const HookFormTextField = ({
  name,
  label,
  error,
  placeholder,
  onChangeText,
  transformInput,
  ...textFieldProps
}: HookFormTextFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { onChange } = field;

        return (
          <TextField
            {...field}
            {...textFieldProps}
            autoCapitalize="none"
            error={error}
            label={label}
            placeholder={placeholder}
            returnKeyType="next"
            onChangeText={(text) => {
              transformInput ? onChange(transformInput(text)) : onChange(text);

              onChangeText?.(text);
            }}
          />
        );
      }}
    />
  );
};

export default HookFormTextField;
