import cx from 'classnames';
import { H2 } from '@missionlane/compass-ui';
import { Button } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HeaderLogo } from '../../Page/HeaderLogo';
import { IconLink, NavLink } from './NavLink';
import { NavSectionHeader } from './NavSectionHeader';
import { SIDEBAR_NAVIGATION_FEATURE_ID } from './constants';
import { NavCardArt } from './NavCardArt';
import {
  NavContainerQuery,
  SettlementOfferState,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import { activeSettlementOfferStatuses } from '@core/components/ChargedOff/utils';
import { OfferType } from '@core/components/ChargedOff/utils/OfferType';
import { OfferIneligibilityReason } from '@core/components/ChargedOff/utils/types';
import isNil from '@core/utils/isNil';
import { MLFlags } from 'flags';
import { useAccountId } from '@core/components/App/Routes/AccountIdContext';

interface NavProps {
  data: NavContainerQuery;
  isMobile: boolean;
  isMobileOpen: boolean;
  onMobileClose: () => void;
}

const Nav = ({ data, isMobile, isMobileOpen, onMobileClose }: NavProps) => {
  const { accountId } = useAccountId();
  const {
    manageFundingAccounts,
    referralsMarketing,
    enableRewardsRedemption,
    enableMyProgress,
    enableMyProgressOnboarding,
  } = useFlags<MLFlags>();

  const {
    creditCards = [],
    customer,
    offerEligibility,
    offers,
    account,
    referralOffers,
    rewards,
  } = data;
  const { hasPendingAccount, contactInfo } = customer || {};
  const currentBalance = account?.balanceInfo.currentBalance;
  const activeSettlementOffer = offers?.find(
    (offer) =>
      offer.offerType === OfferType.Settlement &&
      activeSettlementOfferStatuses.includes(offer.data.state),
  );
  const hasInProgressOffer =
    activeSettlementOffer &&
    Boolean(
      activeSettlementOffer.data.state === SettlementOfferState.InProgress ||
        activeSettlementOffer.data.state ===
          SettlementOfferState.PendingCloseOut,
    );
  const hasFulfilledPifOffer =
    activeSettlementOffer &&
    !isNil(currentBalance) &&
    Boolean(
      activeSettlementOffer.data.state === SettlementOfferState.Fulfilled &&
        (activeSettlementOffer.data.subType === SettlementOfferSubtype.Pif ||
          currentBalance <= 0),
    );
  const settlementOfferEligibility = offerEligibility?.find(
    (offer) => offer.offerType === OfferType.Settlement,
  );
  const isIneligibleForSettlementOffer =
    settlementOfferEligibility && !settlementOfferEligibility.eligible;
  const isInDSCProgram =
    isIneligibleForSettlementOffer &&
    settlementOfferEligibility.ineligibleReasons.includes(
      OfferIneligibilityReason.IN_DSC_PROGRAM,
    );
  const isInKatabat =
    isIneligibleForSettlementOffer &&
    settlementOfferEligibility.ineligibleReasons.includes(
      OfferIneligibilityReason.IN_KATABAT,
    );
  const isMulticard =
    creditCards.length ? creditCards.length > 1 || hasPendingAccount : false;
  const showMulticardHomeNavLink = !isMobile && isMulticard;
  const activeAccountDetails = creditCards.find((cc) => cc.id === accountId);
  const { cardArtUrl, cardLast4, name: cardName } = activeAccountDetails || {};
  const showRewardsNavLink = enableRewardsRedemption && rewards?.isRewardsCard;
  const customerFirstName = contactInfo?.firstName;
  const isChargedOff = account?.statuses.isChargedOff;
  const isBankrupt = account?.statuses.isBankrupt;
  const hasExistingThirdPartySettlementPlan =
    isChargedOff && (isInDSCProgram || isInKatabat);
  const hasReferralsOffer =
    (referralOffers && referralOffers.length > 0) || false;

  const paymentsNavItemLinkTo =
    hasInProgressOffer ?
      `/account/${accountId}/payment-plan`
    : `/account/${accountId}/payments`;
  const bankAccountsNavItemLinkTo = getBankAccountsNavItemLinkTo(
    String(accountId),
    Boolean(hasInProgressOffer),
    manageFundingAccounts,
  );
  const handleMobileClose = isMobile ? onMobileClose : undefined;

  return (
    <>
      {isMobile && isMobileOpen && (
        <div
          id="overlay"
          className="fixed bg-grey opacity-80 top-0 left-0 bottom-0 right-0 z-max"
          onClick={onMobileClose}
        />
      )}
      <div
        data-testid={SIDEBAR_NAVIGATION_FEATURE_ID}
        className={cx(
          'w5 flex flex-column',
          {
            'bg-compass-green pt5 pb4': !isMobile,
            'vh-100 fixed top-0 right-0 transition-transform bg-white z-max pv3 overflow-y-scroll':
              isMobile,
          },
          isMobile && (isMobileOpen ? 'translate-x-0' : 'translate-x-100'),
        )}
      >
        <div className={cx('pb3', isMobile ? 'ph3' : 'ph4')}>
          {isMobile ?
            <MobileNavHeader
              customerFirstName={customerFirstName}
              onClose={onMobileClose}
            />
          : <HeaderLogo color="white" />}
        </div>
        {showMulticardHomeNavLink && (
          <NavLink
            to="/"
            text="All cards"
            icon="back"
            trackingName="Multicard home"
          />
        )}
        {!isMobile && cardArtUrl && (
          <NavCardArt
            classNames={showMulticardHomeNavLink ? 'pt3' : undefined}
            cardArtUrl={cardArtUrl}
            cardName={cardName}
            cardLast4={cardLast4}
          />
        )}
        <NavLink
          to={`/account/${accountId}/summary`}
          text="Home"
          icon="homeOutline"
          onClick={handleMobileClose}
          trackingName="Account summary"
        />
        {!hasExistingThirdPartySettlementPlan &&
          !hasFulfilledPifOffer &&
          !isBankrupt && (
            <NavLink
              to={paymentsNavItemLinkTo}
              text="Payments"
              icon="dollarSign"
              onClick={handleMobileClose}
              trackingName="Payments overview"
            />
          )}
        {(enableMyProgressOnboarding || enableMyProgress) && (
          <NavLink
            to={`/account/${accountId}/my-progress`}
            text="My Progress"
            icon="chart"
            onClick={handleMobileClose}
            trackingName="My Progress"
          />
        )}
        <NavLink
          to={`/account/${accountId}/transactions`}
          text="Transactions"
          icon="transaction"
          onClick={handleMobileClose}
          trackingName="Transactions"
        />
        <NavLink
          to={`/account/${accountId}/statements`}
          text="Statements"
          icon="document"
          onClick={handleMobileClose}
          trackingName="Statements"
        />
        {showRewardsNavLink && (
          <NavLink
            to={`/account/${accountId}/rewards`}
            text="Rewards"
            icon="crown"
            onClick={handleMobileClose}
            trackingName="Rewards"
          />
        )}
        <NavLink
          to={`/account/${accountId}/details`}
          text="Account Details"
          icon="infoCircleLight"
          onClick={handleMobileClose}
          trackingName="Account details"
        />
        <NavLink
          to={`/account/${accountId}/manage-card`}
          text="Manage card"
          icon="creditCard"
          onClick={handleMobileClose}
          trackingName="Manage card"
        />
        <NavSectionHeader text="Your Profile" />
        <NavLink
          to="/my-profile"
          text="Profile"
          icon="user"
          onClick={handleMobileClose}
          trackingName="My profile"
        />
        {!isBankrupt && (
          <NavLink
            to={bankAccountsNavItemLinkTo}
            text="Bank accounts"
            icon="bank"
            onClick={handleMobileClose}
            trackingName={
              manageFundingAccounts ?
                'Manage bank accounts'
              : 'Add bank account'
            }
          />
        )}
        {referralsMarketing !== 'DISABLED' && hasReferralsOffer && (
          <NavLink
            to={`/account/${accountId}/referrals`}
            text="Refer a friend"
            icon="bullhorn"
            onClick={handleMobileClose}
            trackingName="Refer a friend"
          />
        )}
        <NavSectionHeader text="Support" />
        <IconLink
          text="Help center"
          icon="circleQuestion"
          onClick={() => {
            handleMobileClose?.();
            window.open(process.env.REACT_APP_ZENDESK_URL, '_blank');
          }}
          trackingName="Help Center FAQ"
        />
        <IconLink
          text="Send message"
          icon="message"
          onClick={() => {
            handleMobileClose?.();
            window.open(
              `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests`,
              '_blank',
            );
          }}
          trackingName="View Messages"
        />
        <NavLink
          className={cx(isMobile ? 'mt2 mb7' : 'mt5')}
          to="/signout"
          text="Sign out"
          color={isMobile ? 'blue' : undefined}
          trackingName="Sign out"
        />
      </div>
    </>
  );
};

interface MobileNavHeaderProps {
  customerFirstName: string | undefined;
  onClose: () => void;
}

const MobileNavHeader = ({
  customerFirstName,
  onClose,
}: MobileNavHeaderProps) => (
  <div className="flex justify-between items-center">
    <div className="flex-auto">
      {customerFirstName && <H2>Hi, {customerFirstName}</H2>}
    </div>
    <Button
      accessibilityLabel="Close side navigation"
      variant="icon"
      icon="close"
      size="small"
      // @ts-ignore - TODO: Update compass to support custom icon button colors
      color="ink"
      onPress={onClose}
    />
  </div>
);

const getBankAccountsNavItemLinkTo = (
  accountId: string,
  hasInProgressOffer: boolean,
  isManageFundingAccountsFlagOn: boolean,
) => {
  const bankAccountsRoutes = {
    editSettlementOffer: `/account/${accountId}/edit-offer-funding-account`,
    manageFundingAccounts: `/account/${accountId}/manage-bank-accounts`,
    addFundingAccount: `/account/${accountId}/add-bank-account`,
  };

  if (hasInProgressOffer) {
    return bankAccountsRoutes.editSettlementOffer;
  } else if (isManageFundingAccountsFlagOn) {
    return bankAccountsRoutes.manageFundingAccounts;
  } else {
    return bankAccountsRoutes.addFundingAccount;
  }
};

export default Nav;
