import { useOktaAuth } from '@okta/okta-react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const COOKIE_ATTRIBUTES: Cookies.CookieAttributes = {
  domain: process.env.REACT_APP_GRAPHQL_COOKIE_DOMAIN,
  sameSite: 'None',
  secure: true,
};

const CookieManager = () => {
  const { authState } = useOktaAuth();
  const idTokenObject = authState?.idToken;

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      Cookies.remove('id_token', COOKIE_ATTRIBUTES);
      localStorage.removeItem('accountId');
    }
  }, [authState?.isAuthenticated]);

  useEffect(() => {
    if (idTokenObject?.idToken) {
      Cookies.set('id_token', idTokenObject.idToken, COOKIE_ATTRIBUTES);
    }
  }, [idTokenObject?.idToken]);

  return null;
};

export default CookieManager;
