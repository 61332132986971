import React from 'react';
import { GenericFallbackFullPage } from '../GenericFallbacks/GenericFallbackFull';
import DefaultDashboard from './Dashboard/DefaultDashboard';
import {
  PreCardMailedDashboard,
  CardMailedDashboard,
  CardDelayedDashboard,
  DeliveryFailedDashboard,
} from './Dashboard/OnboardingDashboards';
import { IssuanceState } from '@core/graphql/globalTypes';

export const getNewCardDashboardComponent = (
  issuanceState: IssuanceState | undefined,
): React.FC => {
  switch (issuanceState) {
    case IssuanceState.PreMailed:
      return PreCardMailedDashboard;
    case IssuanceState.Mailed:
      return CardMailedDashboard;
    case IssuanceState.Delayed:
      return CardDelayedDashboard;
    case IssuanceState.Returned:
      return DeliveryFailedDashboard;
    case IssuanceState.PreExpired:
    case IssuanceState.Activated:
    case IssuanceState.Canceled:
      return DefaultDashboard;
    default:
      return GenericFallbackFullPage;
  }
};

export const getReplacementCardDashboardComponent = (
  issuanceState: IssuanceState | undefined,
): React.FC => {
  if (issuanceState) {
    return DefaultDashboard;
  }

  return GenericFallbackFullPage;
};
