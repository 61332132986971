import Modal from '@core/components/General/Modal/Modal';
import { getUTC, getET } from '@core/utils/timezones';
import { Notification, P3 } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../DatePicker';

/** @deprecated Use @core/components/General/Datepicker */
class PaymentDateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      warnings: [],
      selected: null || props.selectedDate,
    };

    this.handleDateChanged = this.handleDateChanged.bind(this);

    this.handleFinish = this.handleFinish.bind(this);

    this.validateWarning = this.validateWarning.bind(this);
  }

  getTitles() {
    const format = 'YYYYMMDD';
    const titles = {};

    titles[dayjs().format(format)] = 'Today';

    if (this.props.printDueDate) {
      titles[getUTC(this.props.printDueDate).format(format)] = 'Due';
    }

    return titles;
  }
  handleDateChanged(timestamp) {
    if (!this.validateSelection(timestamp)) {
      return;
    }

    // see comment above validateWarning for explanation
    this.validateWarning(timestamp);

    this.setState({
      selected: timestamp,
    });
  }

  handleFinish() {
    if (!this.validateFinish()) {
      return;
    }

    // adding the notion of index so this can be used in a map
    if (typeof this.props.index === 'number') {
      this.props.onChange(
        getUTC(this.state.selected * 1000).valueOf(),
        this.props.index,
      );
    } else {
      this.props.onChange(getUTC(this.state.selected * 1000).valueOf());
    }

    this.setState({
      warnings: [],
      selected: null,
    });

    this.props.onClose();
  }

  validateFinish() {
    const errors = [];

    if (!this.state.selected) {
      errors.push('You must select a date.');
    }

    this.setState({ errors });

    return !errors.length;
  }

  validateSelection(timestamp) {
    const errors = this.props.dateValidator(
      getET(timestamp * 1000).format('YYYY-MM-DD'),
    );

    this.setState({ errors });

    return !errors.length;
  }

  // warnings are like errors in that they have a validation that's triggered onChange
  // however they do not prevent you from making the selection
  // they only offer a warning that what you're doing may have consequequences
  // this is optional
  validateWarning(timestamp) {
    if (!this.props.warningValidator) {
      return;
    }

    const warnings = this.props.warningValidator(getUTC(timestamp * 1000));

    this.setState({
      warnings,
    });
  }

  render() {
    const { helperText, isOpen, max, min, onClose, startDate, invalidDates } =
      this.props;

    return (
      <Modal
        headerText="Select payment date"
        isOpen={isOpen}
        primaryButton={{ text: 'Select Date', onPress: this.handleFinish }}
        onClose={onClose}
      >
        <DatePicker
          invalidDates={invalidDates}
          max={max}
          min={min}
          selected={this.state.selected || this.props.selectedDate}
          startDate={startDate}
          titles={this.getTitles()}
          onChange={this.handleDateChanged}
        />
        {helperText && <P3>{helperText}</P3>}
        {this.state.warnings.map((warning, index) => (
          <div key={index} className="mb4">
            <Notification level="warning" variant="inline">
              {warning}
            </Notification>
          </div>
        ))}
        {this.state.errors.map((error, index) => (
          <div key={index} className="mb4">
            <Notification level="error" variant="inline">
              {error}
            </Notification>
          </div>
        ))}
      </Modal>
    );
  }
}

PaymentDateModal.defaultProps = {
  printDueDate: null,
  helperText: null,
  min: null,
  max: null,
  warningValidator: undefined,
  invalidDates: [],
};

PaymentDateModal.propTypes = {
  dateValidator: PropTypes.func.isRequired,
  warningValidator: PropTypes.func,
  printDueDate: PropTypes.string,
  helperText: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  invalidDates: PropTypes.array.isRequired,
};

export default PaymentDateModal;
