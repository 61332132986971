import { yupResolver } from '@hookform/resolvers/yup';
import { Notification } from '@missionlane/compass-ui';
import { AuthnTransaction } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import DefaultAuthError from '../DefaultAuthError';
import { OktaErrorMessage } from '../OktaErrorMessage';
import { isOktaError, OktaResponseError } from '../types';
import { usernameSchema } from '../validation';

import LoginActionButtons from './LoginActionButtons';
import LoginContainer from './LoginContainer';
import { LoginLink } from './LoginLink';
import { areCookiesEnabled } from '@core/utils/areCookiesEnabled';
import { useTracking } from '@core/services/TrackService/useTracking';
import { RiskAuthServiceError } from '@core/services/RiskAuthService/errors';
import { HookFormTextField } from '@core/components/Form';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';

const COOKIE_WARNING =
  'Please turn on cookies in your browser settings to sign in.';

const validationSchema = Yup.object().shape({
  username: usernameSchema,
  password: Yup.string().nullable().required('This field is required.'),
});

interface LoginFormValues {
  username: string;
  password: string;
}

interface LoginPageProps {
  onSuccess: (transaction: AuthnTransaction) => void;
}

const LoginPage = ({ onSuccess }: LoginPageProps) => {
  const { trackClick, trackEvent, trackPage } = useTracking();
  const [authError, setAuthError] = useState<OktaResponseError | Error>();
  const [maskPassword, setMaskPassword] = useState(true);
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const { error: riskAuthError, setError: setRiskAuthError } = useRiskAuth();
  const cookiesAreEnabled = useRef(areCookiesEnabled()).current;
  const storedUsername = localStorage.getItem('username') || '';

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      username: storedUsername,
      password: '',
    },
  });

  const {
    handleSubmit,
    setValue,
    getValues, // NOTE: The values do not get updated on rerenders, use watch for that
    formState: { errors, isSubmitting, isValid, touchedFields },
  } = form;

  const fieldNames = Object.keys(getValues());
  const loginError = authError || riskAuthError;

  useEffect(() => {
    trackPage({ name: 'Login', feature: 'Login' });
  }, []);

  useEffect(() => {
    if (!cookiesAreEnabled)
      trackEvent({
        eventName: 'Warning Viewed',
        name: 'Cookies Disabled Warning',
        feature: 'Login',
        error: {
          name: 'Cookies Disabled Warning',
          message: COOKIE_WARNING,
          code: 'W001',
        },
      });
  }, [cookiesAreEnabled]);

  const clearStoredUsername = () => localStorage.setItem('username', '');

  const handleLogin = handleSubmit(async ({ username, password }) => {
    // clear prior status
    setAuthError(undefined);

    setRiskAuthError(undefined);

    trackClick({
      name: 'Login',
      feature: 'Login',
    });

    try {
      const transaction = await oktaAuth.signInWithCredentials({
        username,
        password,
      });

      if (transaction.status === 'MFA_ENROLL') {
        throw new Error('User should not not be MFA_ENROLL.');
      }

      localStorage.setItem('username', username);

      trackEvent({
        feature: 'Login',
        eventName: 'Login Success',
      });

      onSuccess(transaction);
    } catch (e: unknown) {
      if (isOktaError(e) || e instanceof Error) {
        setAuthError(e);
      } else {
        setAuthError(
          new Error('There was an unknown error while attempting to login.'),
        );
      }
    }
  });

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (e.nativeEvent.key === 'Enter') handleLogin();
  };

  return (
    <LoginContainer header="Log in to your account">
      <FormProvider {...form}>
        <div className="mb4 mb4-ns">
          <HookFormTextField
            autoFocus={true}
            error={errors.username?.message}
            id="username"
            label="Email"
            name="username"
            transformInput={(value) => value.trim()}
            onKeyPress={handleKeyPress}
            onChangeText={(text) => {
              // Assume that if the user backspaces this out manually, then the stored
              // username is not their email
              if (!text && storedUsername) clearStoredUsername();
            }}
          />
          {storedUsername && (
            <div className="mt2">
              <LoginLink
                onClick={() => {
                  trackClick({ name: 'Not your email?', feature: 'Login' });

                  clearStoredUsername();

                  setValue('username', '');
                }}
              >
                Not your email?
              </LoginLink>
            </div>
          )}
        </div>
        <HookFormTextField
          error={errors.password?.message}
          id="password"
          label="Password"
          name="password"
          secureTextEntry={maskPassword}
          action={{
            label: maskPassword ? 'Show' : 'Hide',
            onPress: () => setMaskPassword(!maskPassword),
          }}
          onKeyPress={handleKeyPress}
        />
        {loginError && <LoginFormError authError={loginError} />}
        {!cookiesAreEnabled && (
          <Notification level="warning" variant="inline">
            {COOKIE_WARNING}
          </Notification>
        )}
        <div className="mt3">
          <LoginActionButtons
            submitButton={{
              text: 'Log In',
              onPress: handleLogin,
              loading: isSubmitting,
              disabled:
                !isValid &&
                Object.values(touchedFields).length === fieldNames.length,
            }}
          />
        </div>
        <div className="mt4 mb2">
          <LoginLink onClick={() => navigate('../forgot-password')}>
            Forgot your password?
          </LoginLink>
        </div>
        <div className="mb3">
          <LoginLink to="https://apply.missionlane.com/lookup" external>
            New Member? Set up your account.
          </LoginLink>
        </div>
      </FormProvider>
    </LoginContainer>
  );
};

interface LoginFormErrorProps {
  authError: OktaResponseError | RiskAuthServiceError | Error;
}

const LoginFormError = ({ authError }: LoginFormErrorProps) => {
  if (isOktaError(authError)) {
    return <OktaErrorMessage error={authError} />;
  }

  // Just display a generic error for risk auth failures or other non okta auth errors
  return <DefaultAuthError error={authError} />;
};

export default LoginPage;
